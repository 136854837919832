import { useMutation, useReactiveVar } from '@apollo/client';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';

import useWindowSize from '@hooks/useWindowSize';
import { CREATE_VISITOR_INFO_V2 } from '@graphql/mutations';
import { siteOptionVar } from '@store';
import { Mutation, SeeWorkSiteInfo } from '@graphql/types';

import ParkingFormMobile from '@components/feature/RegisterParking/ParkingFormMobile';
import ParkingFormPC from '@components/feature/RegisterParking/ParkingFormPC';

export type ParkingInfoTypes = {
    site: string;
    siteUnique: string;
    type: string;
    company: string;
    name: string;
    phone1: string;
    phone2: string;
    phone3: string;
    path: string;
    staffTeam: string;
    staffName: string;
    carLicenseNum: string;
    hour: string;
    minute: string;
};

const RegisterParking = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);
    // 서약서 모달
    const [openVow, setOpenVow] = useState<boolean>(false);
    // 약관 토글
    const [agree, setAgree] = useState<boolean>(false);
    const [info, setInfo] = useState<ParkingInfoTypes>({
        site:
            JSON.parse(localStorage.getItem('siteInfo') as string)?.ws_name ??
            '',
        siteUnique:
            JSON.parse(localStorage.getItem('siteInfo') as string)?.ws_unique ??
            '',
        type: 'visitor',
        company: '',
        name: '',
        phone1: '010',
        phone2: '',
        phone3: '',
        path: 'justCar',
        staffTeam: '',
        staffName: '',
        carLicenseNum: '',
        hour: '', // number. 빈값 0
        minute: '', // number. 빈값 0
    });

    // mutation 리졸버
    const [createVisitorInfo_v2] = useMutation<
        Pick<Mutation, 'createVisitorInfo_v2'>
    >(CREATE_VISITOR_INFO_V2);

    // 정보 업데이트
    const infoUpdateHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | SelectChangeEvent,
        ) => {
            const { value, name } = e.target;
            const { maxLength } = e.target as HTMLInputElement;
            setInfo((prev) => ({ ...prev, [name]: value.slice(0, maxLength) }));
            if (name === 'site') {
                const selectedInfo = siteList.filter(
                    (info) => info.ws_unique === e.target.value,
                );
                localStorage.setItem(
                    'siteInfo',
                    JSON.stringify(selectedInfo[0]),
                );
            }
        },
        [siteList],
    );

    // 주차 등록
    const submitHandler = useCallback(() => {
        const {
            site,
            siteUnique,
            type,
            company,
            name,
            phone1,
            phone2,
            phone3,
            path,
            staffTeam,
            staffName,
            carLicenseNum,
            hour,
            minute,
        } = info;

        createVisitorInfo_v2({
            variables: {
                workSiteUnique: siteUnique,
                rvType: type,
                rvCompanyName: company,
                rvName: name,
                rvCellPhone: `${phone1}${phone2}${phone3}`,
                rvVisitPath: path,
                rvCarLicenseNumber: carLicenseNum,
                rvHour: hour ? Number(hour) : 0,
                rvMin: minute ? Number(minute) : 0,
                rvTeam: staffTeam,
                rvManagerName: staffName,
            },
            onCompleted: () => {
                // 정의 필요
                alert('주차 등록이 완료되었습니다.');
                setOpenVow(false);
                navigate('/');
            },
            onError: (err) => {
                console.log(err);
            },
        });
    }, [createVisitorInfo_v2, info, navigate]);

    return (
        <>
            {width < 1280 && (
                <ParkingFormMobile
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    submitHandler={submitHandler}
                />
            )}
            {width >= 1280 && (
                <ParkingFormPC
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    submitHandler={submitHandler}
                />
            )}
        </>
    );
};

export default RegisterParking;
