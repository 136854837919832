import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import useWindowSize from '@hooks/useWindowSize';
import { userSiteNameValue, siteOptionVar, userSiteInfo } from '@store';
import { SeeWorkSiteInfo } from '@graphql/types';

import MainPC from '@components/feature/Main/MainPC';
import MainMobile from '@components/feature/Main/MainMobile';

const Main = () => {
    const location = useLocation();
    const { width } = useWindowSize();
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);
    // const siteName: string = useReactiveVar(userSiteNameValue);
    const siteInfo: SeeWorkSiteInfo = useReactiveVar(userSiteInfo);

    // 모바일 url param으로 site value 만들기
    useEffect(() => {
        if (location.pathname !== '/') {
            // const selectedSite = `무신사캠퍼스${location.pathname
            //     .replace('/', '')
            //     .toUpperCase()}`;
            // userSiteNameValue(selectedSite);
            // localStorage.setItem('selectedSite', selectedSite);
            const siteUnique = location.pathname.replace('/', '');
            const selectedInfo = siteList.filter(
                (info) => info.ws_unique === siteUnique,
            );
            userSiteInfo(selectedInfo[0]);
            localStorage.setItem('siteInfo', JSON.stringify(selectedInfo[0]));
        }
    }, [location, siteList]);

    return (
        <>
            {width < 1280 && <MainMobile />}
            {width >= 1280 && <MainPC />}
        </>
    );
};

export default Main;
