import { gql } from '@apollo/client';

export const CREATE_VISITOR_INFO_V2 = gql`
    mutation CreateVisitorInfo_v2(
        $workSiteUnique: String!
        $rvType: receptionVisitorType!
        $rvName: String!
        $rvCellPhone: String!
        $rvVisitPath: visitPath!
        $rvCarLicenseNumber: String!
        $rvHour: Int!
        $rvMin: Int!
        $rvManagerName: String!
        $rvTeam: String
        $rvCompanyName: String
    ) {
        createVisitorInfo_v2(
            workSiteUnique: $workSiteUnique
            rv_type: $rvType
            rv_name: $rvName
            rv_cellPhone: $rvCellPhone
            rv_visitPath: $rvVisitPath
            rv_carLicenseNumber: $rvCarLicenseNumber
            rv_hour: $rvHour
            rv_min: $rvMin
            rv_managerName: $rvManagerName
            rv_team: $rvTeam
            rv_companyName: $rvCompanyName
        )
    }
`;

export const CREATE_MUPEOPLE_VISITCARD = gql`
    mutation CreateMuPeopleVisitCard(
        $workSiteUnique: String!
        $mpvcTeamName: String!
        $mpvcName: String!
        $mpvcCellphone: String!
        $mpvcReturnDate: String!
        $mpvcReason: String!
    ) {
        createMuPeopleVisitCard(
            workSiteUnique: $workSiteUnique
            mpvc_teamName: $mpvcTeamName
            mpvc_name: $mpvcName
            mpvc_cellphone: $mpvcCellphone
            mpvc_returnDate: $mpvcReturnDate
            mpvc_reason: $mpvcReason
        )
    }
`;
