import { useMutation, useReactiveVar } from '@apollo/client';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { SelectChangeEvent } from '@mui/material/Select';

import useWindowSize from '@hooks/useWindowSize';
import { userSiteNameValue, siteOptionVar } from '@store';
import {
    CREATE_VISITOR_INFO_V2,
    CREATE_MUPEOPLE_VISITCARD,
} from '@graphql/mutations';
import { Mutation, SeeWorkSiteInfo } from '@graphql/types';

import VistorFormPC from '@components/feature/RegisterVisitor/VistorFormPC';
import VistorFormMobile from '@components/feature/RegisterVisitor/VistorFormMobile';

export type infoTypes = {
    site: string;
    siteUnique: string;
    type: string;
    company: string;
    name: string;
    returnDate: string;
    rentalReason: string;
    phone1: string;
    phone2: string;
    phone3: string;
    path: string;
    staffTeam: string;
    staffName: string;
    team: string;
    carLicenseNum: string;
    hour: string;
    minute: string;
};

const RegisterVistor = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);
    // 서약서 모달
    const [openVow, setOpenVow] = useState<boolean>(false);
    // 약관 토글
    const [agree, setAgree] = useState<boolean>(false);
    const [info, setInfo] = useState<infoTypes>({
        site:
            JSON.parse(localStorage.getItem('siteInfo') as string)?.ws_name ??
            '',
        siteUnique:
            JSON.parse(localStorage.getItem('siteInfo') as string)?.ws_unique ??
            '',
        type: 'visitor',
        company: '',
        name: '',
        returnDate: new Date().toISOString(),
        rentalReason: '',
        phone1: '010',
        phone2: '',
        phone3: '',
        path: 'walk',
        staffTeam: '',
        staffName: '',
        team: '',
        carLicenseNum: '',
        hour: '', // number. 빈값 0
        minute: '', // number. 빈값 0
    });

    // mutation 리졸버
    const [createVisitorInfo_v2] = useMutation<
        Pick<Mutation, 'createVisitorInfo_v2'>
    >(CREATE_VISITOR_INFO_V2);

    const [createMuPeopleVisitCard] = useMutation<
        Pick<Mutation, 'createMuPeopleVisitCard'>
    >(CREATE_MUPEOPLE_VISITCARD);

    // 정보 업데이트
    const infoUpdateHandler = useCallback(
        (
            e:
                | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
                | SelectChangeEvent,
        ) => {
            const { value, name } = e.target;
            const { maxLength } = e.target as HTMLInputElement;
            setInfo((prev) => ({ ...prev, [name]: value.slice(0, maxLength) }));
            // if (name === 'site') localStorage.setItem('selectedSite', value);
            if (name === 'site') {
                const selectedInfo = siteList.filter(
                    (info) => info.ws_unique === e.target.value,
                );
                localStorage.setItem(
                    'siteInfo',
                    JSON.stringify(selectedInfo[0]),
                );
            }
        },
        [siteList],
    );

    //날짜 업데이트
    const dateChangeHandler = useCallback((date: string) => {
        setInfo((prev) => ({
            ...prev,
            returnDate: date,
        }));
    }, []);

    // 방문자 등록
    const submitHandler = useCallback(() => {
        const {
            site,
            siteUnique,
            type,
            company,
            name,
            returnDate,
            rentalReason,
            phone1,
            phone2,
            phone3,
            path,
            staffTeam,
            staffName,
            team,
            carLicenseNum,
            hour,
            minute,
        } = info;

        if (info.type === 'visitor') {
            createVisitorInfo_v2({
                variables: {
                    workSiteUnique: siteUnique,
                    rvType: type,
                    rvCompanyName: company,
                    rvName: name,
                    rvCellPhone: `${phone1}${phone2}${phone3}`,
                    rvVisitPath: path,
                    rvCarLicenseNumber: carLicenseNum,
                    rvHour: hour ? Number(hour) : 0,
                    rvMin: minute ? Number(minute) : 0,
                    rvTeam: staffTeam,
                    rvManagerName: staffName,
                },
                onCompleted: () => {
                    alert(
                        '방문자 등록이 완료되었습니다. \n리셉션에서 방문증을 수령해주세요.',
                    );
                    // userSiteNameValue(site);
                    setOpenVow(false);
                    navigate('/');
                },
                onError: (err) => {
                    console.log(err);
                },
            });
        } else if (info.type === 'musinsa') {
            createMuPeopleVisitCard({
                variables: {
                    workSiteUnique: siteUnique,
                    mpvcTeamName: team,
                    mpvcName: name,
                    mpvcCellphone: `${phone1}${phone3}${phone3}`,
                    mpvcReturnDate: returnDate,
                    mpvcReason: rentalReason,
                },
                onCompleted: () => {
                    alert(
                        '방문증 대여 신청이 완료되었습니다. \n리셉션에서 방문증을 수령해주세요.',
                    );
                    setOpenVow(false);
                    navigate('/');
                },
                onError: (err) => {
                    console.log(err);
                },
            });
        }
    }, [createMuPeopleVisitCard, createVisitorInfo_v2, info, navigate]);

    return (
        <>
            {width < 1280 && (
                <VistorFormMobile
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    dateChangeHandler={dateChangeHandler}
                    submitHandler={submitHandler}
                />
            )}
            {width >= 1280 && (
                <VistorFormPC
                    openVow={openVow}
                    agree={agree}
                    info={info}
                    setOpenVow={setOpenVow}
                    setAgree={setAgree}
                    setInfo={setInfo}
                    infoUpdateHandler={infoUpdateHandler}
                    dateChangeHandler={dateChangeHandler}
                    submitHandler={submitHandler}
                />
            )}
        </>
    );
};

export default RegisterVistor;
