import { useState, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';

import { VistorFormTypes } from './VistorFormMobile';
import { visitorValidation } from '@utils/common';
import { siteOptionVar, userSiteInfo } from '@store/index';
import { VISITOR_TYPE, VISITOR_PATH } from '@constants';
import { SeeWorkSiteInfo } from '@graphql/types';

import Header from '@components/layout/Header';
import StyledInput from '@components/styled/StyledInput';
import StyledCheckbox from '@components/styled/StyledCheckbox';
import StyledButton from '@components/styled/StyledButton';
import MuiDatePicker from '@components/styled/MuiDatePicker';
import MuiSelect from '@components/styled/MuiSelect';
import AgreementTerm from '@components/share/AgreementTerm';
import VowModal from '@components/share/VowModal';

const VistorFormPC = ({
    openVow,
    agree,
    info,
    setOpenVow,
    setAgree,
    setInfo,
    infoUpdateHandler,
    dateChangeHandler,
    submitHandler,
}: VistorFormTypes) => {
    const phoneRef = useRef<HTMLInputElement>(null);

    const [openAgree, setOpenAgree] = useState<boolean>(false);
    const siteList: SeeWorkSiteInfo[] = useReactiveVar(siteOptionVar);
    const siteInfo: SeeWorkSiteInfo = useReactiveVar(userSiteInfo);
    // 화면 사이즈 변동 시 전호번호 양식 변경 처리
    useEffect(() => {
        if (info.phone2.length > 4) {
            setInfo((prev) => ({
                ...prev,
                phone2: info.phone2.slice(0, 4),
                phone3: info.phone2.slice(4),
            }));
        }
    }, [info.phone2, setInfo]);

    const siteArr = siteList.map((list) => {
        return { type: list.ws_unique, text: list.ws_name };
    });

    const inputNumberOnly = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                e.key === '-' ||
                e.key === '+' ||
                e.key === '.' ||
                e.key === 'e'
            ) {
                e.preventDefault();
            }
        },
        [],
    );

    // 전화번호 입력 자동 오토 포커스
    const autofocusHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value, maxLength } = e.target;
            if (value.length === maxLength) {
                phoneRef.current!.focus();
            }
        },
        [],
    );

    // 유효성 체크 및 서약서 오픈 함수
    const checkValidationHandler = useCallback(() => {
        if (!visitorValidation(info, agree)) return;
        if (info.phone2.length !== 4 || info.phone3.length !== 4) {
            alert('연락처를 정확히 입력해주세요.');
            return;
        }
        setOpenVow(true);
    }, [info, agree, setOpenVow]);

    return (
        <Container>
            <Header />
            <Section>
                <Title>방문자 등록</Title>
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <InputTitle>사이트</InputTitle>
                        <MuiSelect
                            name="site"
                            value={
                                JSON.parse(
                                    localStorage.getItem('siteInfo') as string,
                                )?.ws_unique ?? ''
                            }
                            onChange={infoUpdateHandler}
                            options={siteArr}
                            placeholder="지점을 선택해주세요"
                            width="440px"
                            disabled={
                                JSON.parse(
                                    localStorage.getItem('siteInfo') as string,
                                )?.ws_unique
                                    ? true
                                    : false
                            }
                        />
                    </FieldWrapper>
                )}
                <FieldWrapper>
                    <InputTitle>구분</InputTitle>
                    <MuiSelect
                        name="type"
                        value={info.type}
                        onChange={infoUpdateHandler}
                        options={VISITOR_TYPE}
                        width="440px"
                    />
                </FieldWrapper>
                {info.type === 'musinsa' && (
                    <FieldWrapper>
                        <InputTitle>소속부서</InputTitle>
                        <StyledInput
                            name="team"
                            value={info.team}
                            placeholder="소속부서를 입력해주세요"
                            onChange={(e) => infoUpdateHandler(e)}
                            width="440px"
                            maxLength={40}
                        />
                    </FieldWrapper>
                )}
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <InputTitle>업체명</InputTitle>
                        <StyledInput
                            name="company"
                            value={info.company}
                            placeholder="법인명을 입력해주세요"
                            onChange={(e) => infoUpdateHandler(e)}
                            width="440px"
                            maxLength={50}
                        />
                    </FieldWrapper>
                )}
                <FieldWrapper>
                    <InputTitle>이름</InputTitle>
                    <StyledInput
                        name="name"
                        value={info.name}
                        placeholder="성함을 입력해주세요"
                        onChange={(e) => infoUpdateHandler(e)}
                        width="440px"
                        maxLength={20}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <InputTitle>연락처</InputTitle>
                    <InputWrapper>
                        <StyledInput
                            type="number"
                            name="phone1"
                            value={info.phone1}
                            onChange={(e) => infoUpdateHandler(e)}
                            onWheel={(e: React.MouseEvent<HTMLInputElement>) =>
                                e.currentTarget.blur()
                            }
                            onKeyDown={(e) => inputNumberOnly(e)}
                            maxLength={3}
                            width="131px"
                        />
                        <InputText>-</InputText>
                        <StyledInput
                            type="number"
                            name="phone2"
                            value={info.phone2}
                            onChange={(e) => {
                                infoUpdateHandler(e);
                                autofocusHandler(e);
                            }}
                            onWheel={(e: React.MouseEvent<HTMLInputElement>) =>
                                e.currentTarget.blur()
                            }
                            onKeyDown={(e) => inputNumberOnly(e)}
                            maxLength={4}
                            width="131px"
                        />
                        <InputText>-</InputText>
                        <StyledInput
                            type="number"
                            name="phone3"
                            value={info.phone3}
                            onChange={(e) => infoUpdateHandler(e)}
                            onWheel={(e: React.MouseEvent<HTMLInputElement>) =>
                                e.currentTarget.blur()
                            }
                            onKeyDown={(e) => inputNumberOnly(e)}
                            maxLength={4}
                            width="131px"
                            ref={phoneRef}
                        />
                    </InputWrapper>
                </FieldWrapper>
                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <InputTitle>방문경로</InputTitle>
                        <MuiSelect
                            name="path"
                            value={info.path}
                            onChange={infoUpdateHandler}
                            options={VISITOR_PATH}
                            width="440px"
                        />
                    </FieldWrapper>
                )}
                {info.type === 'visitor' && info.path === 'car' && (
                    <>
                        <FieldWrapper>
                            <InputTitle>차량번호</InputTitle>
                            <StyledInput
                                name="carLicenseNum"
                                value={info.carLicenseNum}
                                placeholder="차량번호를 입력해주세요"
                                onChange={(e) => infoUpdateHandler(e)}
                                width="440px"
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <InputTitle>출차예정시간</InputTitle>
                            <InputWrapper>
                                <StyledInput
                                    type="number"
                                    name="hour"
                                    value={info.hour}
                                    placeholder="00"
                                    onChange={(e) => infoUpdateHandler(e)}
                                    onWheel={(
                                        e: React.MouseEvent<HTMLInputElement>,
                                    ) => e.currentTarget.blur()}
                                    onKeyDown={(e) => inputNumberOnly(e)}
                                    maxLength={2}
                                    width="210px"
                                />
                                <InputText>:</InputText>
                                <StyledInput
                                    type="number"
                                    name="minute"
                                    value={info.minute}
                                    placeholder="00"
                                    onChange={(e) => infoUpdateHandler(e)}
                                    onWheel={(
                                        e: React.MouseEvent<HTMLInputElement>,
                                    ) => e.currentTarget.blur()}
                                    onKeyDown={(e) => inputNumberOnly(e)}
                                    maxLength={2}
                                    width="210px"
                                />
                            </InputWrapper>
                        </FieldWrapper>
                    </>
                )}

                {info.type === 'visitor' && (
                    <FieldWrapper>
                        <InputTitle>담당자정보</InputTitle>
                        <InputWrapper>
                            <StyledInput
                                name="staffTeam"
                                value={info.staffTeam}
                                placeholder="팀명을 입력해주세요"
                                onChange={(e) => infoUpdateHandler(e)}
                                width="216px"
                                maxLength={50}
                            />
                            <StyledInput
                                name="staffName"
                                value={info.staffName}
                                placeholder="담당자 이름울 입력해주세요"
                                onChange={(e) => infoUpdateHandler(e)}
                                width="216px"
                                maxLength={30}
                            />
                        </InputWrapper>
                    </FieldWrapper>
                )}
                {info.type === 'musinsa' && (
                    <>
                        <FieldWrapper>
                            <InputTitle>출입증 반납 예정일</InputTitle>
                            <MuiDatePicker
                                value={info.returnDate}
                                onChange={(date) =>
                                    dateChangeHandler(date as string)
                                }
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <InputTitle>대여사유</InputTitle>
                            <StyledInput
                                name="rentalReason"
                                value={info.rentalReason}
                                placeholder="출입증 대여사유를 입력해주세요"
                                onChange={(e) => infoUpdateHandler(e)}
                                width="440px"
                                maxLength={250}
                            />
                        </FieldWrapper>
                    </>
                )}
                <AgreementWrapper>
                    <InputTitle>유의사항</InputTitle>
                    <Agreement>
                        <Guide>
                            <GuideList>
                                <ListDisc />
                                출차 예정시간을 넘어서 출차 하시는 경우,
                                리셉션을 방문하시어 주차권 발급 요청해주시길
                                바랍니다.
                            </GuideList>
                            <GuideList>
                                <ListDisc />
                                임시 출입증 대여 시 반드시 당일 이내에
                                반납해주세요.
                            </GuideList>
                            <GuideList>
                                <ListDisc />
                                장기 출입증 대여는 리셉션에 별도 문의 해주세요.
                            </GuideList>
                            <GuideList>
                                <ListDisc />
                                출입증 미 반납시 알림톡이 전송될 수 있는 점 참고
                                부탁드립니다.
                            </GuideList>
                            <GuideList>
                                <ListDisc />
                                개인정보 제 3자 제공 동의
                                <SeeMoreBtn
                                    onClick={() =>
                                        setOpenAgree((prev) => !prev)
                                    }
                                >
                                    {openAgree ? '닫기' : '보기'}
                                </SeeMoreBtn>
                            </GuideList>
                        </Guide>
                        {openAgree && <AgreementTerm site={info.site} />}
                        <StyledCheckbox
                            id="agree"
                            name="agree"
                            label="예약정보 및 유의사항에 동의합니다."
                            checked={agree}
                            onChange={() => setAgree((prev) => !prev)}
                        />
                    </Agreement>
                </AgreementWrapper>
                <BtnArea>
                    <StyledButton
                        title="등록하기"
                        width={'140px'}
                        onClick={checkValidationHandler}
                    />
                </BtnArea>
            </Section>
            {createPortal(
                openVow && (
                    <VowModal
                        openVow={openVow}
                        setOpenVow={setOpenVow}
                        info={info}
                        submitHandler={submitHandler}
                    />
                ),
                document.body,
            )}
        </Container>
    );
};

export default VistorFormPC;

const Container = styled.div`
    margin: 0 auto;
    height: 100vh;
    font-family: AppleSDGothicNeoRegular, sans-serif;
`;

const Section = styled.section`
    margin: 0 auto;
    padding: 100px 0;
    width: 620px;
`;

const Title = styled.h2`
    margin-bottom: 32px;
    font-size: 32px;
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const FieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
`;

const InputTitle = styled.span`
    font-family: AppleSDGothicNeoBold, sans-serif;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 440px;
`;

const InputText = styled.span`
    color: ${(props) => props.theme.colors.placeholder};
`;

const AgreementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
`;
const Agreement = styled.div`
    word-break: keep-all;
    line-height: 1.5;
    width: 440px;
`;

const Guide = styled.ul``;

const GuideList = styled.li`
    position: relative;
    margin-bottom: 8px;
    padding-left: 8px;
    color: ${(props) => props.theme.colors.placeholder};
    font-size: 14px;
    > span {
        color: ${(props) => props.theme.colors.fontColor};
    }
    &:last-of-type {
        margin-bottom: 30px;
    }
`;

const ListDisc = styled.span`
    position: absolute;
    top: 8px;
    left: 0;
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grayText};
`;

const SeeMoreBtn = styled.button`
    margin-left: 5px;
    color: ${(props) => props.theme.colors.fontColor};
    font-size: 14px;
    font-family: AppleSDGothicNeoRegular, sans-serif;
    text-decoration: underline;
`;

const BtnArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;
