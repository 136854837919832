import { makeVar } from '@apollo/client';
import { SeeWorkSiteInfo } from '@graphql/types';
// 초기 상태
const userSiteName = '';
const initialSiteOption: SeeWorkSiteInfo[] = [];

export const isDarkMode = makeVar(false);
export const userSiteNameValue = makeVar(userSiteName);
export const userSiteInfo = makeVar({});
export const siteOptionVar = makeVar(initialSiteOption);
